var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Feedback',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" The "),_c('code',[_vm._v("<b-spinner>")]),_vm._v(" component can be used to show the loading state in your projects. They're rendered only with basic HTML and CSS as a lightweight Vue functional component. Their appearance, alignment, and sizing can be easily customized with a few built-in props and/or Bootstrap v4 utility classes. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('border-item')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('colors-item')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('grow-item')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('flex-item')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('floats-item')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('text-align-item')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('size-item')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('buttons-item')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }